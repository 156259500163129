import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('video_story', () => ({
  timer: null,
  time: 0,
  delay: 100,
  barHeight: 0,
  fullHeight: 0,
  videoStyle: 'height: 100%;',
  testUp: 0,
  testDown: 0,
  init() {

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }, 100)
/*
    this.calculateBarHeight(this);

    this.time = Date.now();
    
    let context = this;
    window.addEventListener('scroll', function () {
      const now = Date.now();
      if (context.time + context.delay < now) {
        if (context.timer) clearTimeout(context.timer);
        context.timer = setTimeout(context.calculateBarHeight(context), context.delay);
        context.time = now;
      }
    });*/

  },
  calculateBarHeight(context) {
    const visibleHeight = window.innerHeight;
    context.fullHeight = context.$refs.control_height.clientHeight;
    context.barHeight = context.fullHeight - visibleHeight;
    context.videoStyle = 'height: calc(100% - ' + context.barHeight + 'px);';
  },
  scrollUp(index) {
    this.$event.preventDefault();

    let target = (index == 0) ? 0 : this.fullHeight * (index - 1);

    window.scrollTo({
      top: target,
      behavior: "smooth",
    });
    this.testUp = target
  },
  scrollDown(index) {
    this.$event.preventDefault();

    let target = this.fullHeight * (index + 1);

    window.scrollTo({
      top: target,
      behavior: "smooth",
    });
    this.testDown = target
  }
}))