import flatpickr from "flatpickr";
import { Danish } from "flatpickr/dist/l10n/da.js"
import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('date_picker', (config) => ({
  enableFuture: config.enableFuture,
  archive_page: config.archive_page,
  init() {
    let options = {
      onChange: this.goToPage.bind(this),
      wrap: true,
      locale: Danish,
    };

    if(this.enableFuture) {
      options.maxDate = new Date().fp_incr(13);
    } else {
      options.maxDate = "today";
    }

    flatpickr(this.$refs.element, options)
  },
  goToPage(selectedDates, dateStr, instance) {
    window.location.href = window.location.origin + "/" + (this.archive_page != "" ? this.archive_page : window.location.pathname.substring(1)) + "?date=" + dateStr;
  }
}));